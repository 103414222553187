.top {
    text-align: center;
    margin-top: 6vh;
}

.cover {
    width: 100%;
    height: 60vh;
    text-align: left;
    background: url('../../images/coverMe.jpg');
    width: 100%;
    height: 60vh;
    background-size: 100%;
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
}

.cover-bio{
    padding-left: 20vh;
    padding-top: 10vh;
}

.cover h1, .cover h3{
    margin: 0;
    color: #555bf5;
    text-shadow: 1px 1px 15px rgb(92, 92, 92);
}

.welcome {
    background-color:#555bf5;
    color: white;
    margin: 0%;
    padding: 5vh;
    box-shadow: 1px 1px 15px rgb(92, 92, 92);
}

.welcome h1 {
    margin: 0%;
}

.headshot{
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 50%;
}

.me {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 3%;
}

.top p {
    font-size: large;
}

.top form {
    background: rgba(234, 230, 252, 0.568);
    border: 3px solid #555bf5;
    border-radius: 20px;
    display: grid;
    margin: 10% 35% 5% 35%;
    box-shadow: 1px 1px 15px rgb(92, 92, 92);
}

.top form p {
    padding: 0 5% 0 5%;
    font-size:100%;
}

.top input, .top textarea {
    margin: 5% 25% 2% 25%;
    border-radius: 5px;
    border: 2px solid #555bf5;
    padding: 4px;
}

.top textarea {
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    height: 10vh;
    resize: none;
}

.top button {
    margin: 5% 40% 5% 40%;
    border-radius: 5px;
    border: 2px solid rgb(188, 168, 252);
    background-color: #555bf5;
    padding: 4px;
    color: white;
}

.top button:hover {
    background-color: rgb(188, 168, 252);
    border-color: #555bf5;
    color:#171b8b;
}

.top h3 {
    font-weight: 400;
    line-height: 3rem;
    opacity: 1;
}

@media all and (max-width: 850px) {
    .top form {
        margin: 3em;
    }

    .cover {
        height: 40vh;
    }

    .cover-bio {
        padding: 5%;
        padding-top: 10%;
        text-align: center;
    }

    .welcome {
        text-align: start;
    }

    .me{
        margin: 15% 1% 10% 1%;
    }
}