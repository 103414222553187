.navbar {
    width: 100%;
    background: #555bf5;
    padding: 20px 10px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 1px 1px 20px rgb(66, 66, 66);
    position: fixed;
    top: 0;
}

.navbar ul {
    display: flex;
    margin: 0 auto;
    max-width: 960px;
    align-items: center;
}

.title {
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
}

.navbar button,
.navbar a {
    margin-left: 16px;
}

.navbar a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.navbar a:hover {
    color:rgb(188, 168, 252);
    transition: color .4s ease;
    text-shadow: 0px 0px 20px #1a1a1a
}