footer {
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 5% 0 0 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #555bf5;
    padding-top: 40px;
}

.footerContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.footerContent h3 {
    font-weight: 400;
    line-height: 3rem;
}

.socials {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
    padding: 0;
}

.socials li {
    margin: 0 10px;
}

.socials a img{
    text-decoration: none;
    filter: invert(1);
    
}

.socials a:hover {
    filter: invert(0.9);
    transition: filter .4s ease;
}

.gh {
    width: 60px;
    height: 60px;
}

.li {
    width: 60px;
    height: 60px;
}

.footerBottom {
    width: 100vw;
    padding: 20px 0;
    text-align: center;
    background-color: #2a2fd7;
}

.footerBottom p {
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
    margin-bottom: 0px;
}



