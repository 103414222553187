.projects {
    text-align: center;
}

.projects a {
    text-decoration: none;
}

.projtitle {
    padding-top: 10vh;
}

.cards {
    margin: 0 auto;
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;  
}


.card:hover .cardCont{
    background-color:rgb(225, 218, 248);
    transition: background-color .4s ease;
}

.card:hover .cardInfo{
    background-color:rgb(208, 195, 252);
    transition: background-color .4s ease;
}

.card img{
    display: block;
    object-fit: cover;
    width: 100%;
    height: 10em;
}

.cardCont {
    line-height: 1.5;
    font-size: 0.9em;
    padding: 15px;
    background: #fafafa;
}

.cardInfo {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background: #eee;
    font-size: 0.8em;
    border-bottom: 2px solid #ccc;
    color: #555;
}

.BiLike {
    font-size: 0.9em;
}

@media all and (max-width: 1000px) {

    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .card {
        margin: 1em;
    }

}

@media all and (max-width: 700px) {

    .cards {
        grid-template-columns: repeat(1, 1fr);
    }

}