.about {
    text-align: center;
}

.download-btn {
    background-color: rgb(177, 182, 251);
    color: black;
    padding: 1rem 2rem;
    font-size: 2rem;
    text-decoration: none;
    border-radius:5px;
    box-shadow: 0 0 7px #434343;
}

.fa-download {
    margin-left: 2%;
    margin-top: 10%;
}

.download-btn:hover {
    background-color: RoyalBlue;
}

.code {
    width: 70vh;
    margin: 0;
    border-radius: 10px;
    box-shadow: 0 0 7px #434343;
}

.aboutcard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6em;
}

.about1cont {
    background-color: rgb(177, 182, 251);
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 0 7px #434343;
    margin-left: -1%;
    width: 40vh;
}

.about2cont {
    background-color: rgb(177, 182, 251);
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0 0 7px #434343;
    margin-left: -60%;
    margin-right: 27vh;
    width: 40vh;
}
@media all and (max-width: 1000px) {
    .aboutcard {
        flex-direction: column;
    }

    .code {
        width: 40vh;
    }

    .about1cont, .about2cont {
        margin: 0;
        margin-top: -1vh;
        width: 36.1vh;
        border-radius: 0 0 10px 10px;
    }

    .download-btn {
        font-size: 1rem;
    }

    .fa-download {
        margin-top: 30%;
    }
}